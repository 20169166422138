import React from "react";
import "./label.css";

const Label: React.FC<React.PropsWithChildren<{ className: string }>> = ({
  children,
  className,
}) => {
  return <span className={["label", className].join(" ")}>{children}</span>;
};

export default Label;
