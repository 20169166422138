import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Program = {
  uuid: string;
  name: string;
  description: string;
  placesDetail: string;
  isSelected: boolean;
  brandLogo: string;
  brandColor: string;
  brandPattern: string;
};

// Define a type for the slice state
interface ProgramState {
  program: Program | null;
}

// Define the initial state using that type
const initialState: ProgramState = {
  program: null,
};

export const programSlice = createSlice({
  name: "program",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addProgram: (state, action: PayloadAction<Program>) => {
      // console.log("Adding program to store");
      // console.log(action.payload);
      // Replace the current program with the new one
      state.program = action.payload;
    },
    removeProgram: (state) => {
      // Clear the current program
      state.program = null;
    },
  },
});

export default programSlice.reducer;
