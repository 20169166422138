import React from "react";
import "./membership-card.css";
import { ReactComponent as VoucherIcon } from "../../assets/symbols/voucher.svg";
import QrCodeSmallImage from "../../assets/temp/qr-code-small@3x.png";
import { GetQRcode } from "../../services/api/getQRcode";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import { Membership } from "../../types/membership";

const MembershipCard: React.FC<{
  backgroundColor?: string;
  voucherCount?: number;
  brandPattern?: string;
}> = ({ backgroundColor, voucherCount, brandPattern }) => {
  const loggedInUser = getLoggedInUser();
  let background;
  if (backgroundColor) {
    background = { backgroundColor: backgroundColor };
  } else {
    background = {
      backgroundImage: 'url("./membership-card-background@3x.jpg")',
    };
  }
  const divStyle = {
    "--color": backgroundColor,
  } as React.CSSProperties;
  return (
    <div className="membership-card">
      <div className={`membership-card__body ${brandPattern}`} style={divStyle}>
        <div className="membership-card__qr-preview">
          <img
            className="membership-card__qr-code-pic"
            src={GetQRcode(loggedInUser?.uuid ?? "")}
            alt="Userpic"
          />
        </div>
      </div>
      <div className="membership-card__footer">
        <a className="membership-card__name" href="#">
          {loggedInUser?.name}
        </a>
        <a className="membership-card__total-vouchers" href="#">
          <VoucherIcon className="membership-card__voucher-icon" />
          {voucherCount}
        </a>
      </div>
    </div>
  );
};

export default MembershipCard;
