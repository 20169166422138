import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authCompletedImagePath from "./content/emailOtp-background.png";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { useLocation, useNavigate } from "react-router-dom";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import openNotification from "../../Components/Antd/Notification/Notification";
import { updateName } from "../../services/api/updateName";
import { updateEmail } from "../../services/api/updateEmail";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { useSelector, useDispatch } from "react-redux";
import { programSlice } from "../../store/slices/data";
import { joinMembership } from "../../services/api/joinMembership";
import { Checkbox } from "antd";
import { SendEmailVerification } from "../../services/api/sendEmailVerification";
import useViewportMeta from "../../hooks/useViewportMeta";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

const getHaveMembership = async (loggedInUserUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.length > 0;
};

function EmailOpt() {
  useViewportMeta();
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();
  const [displayName, setDisplayName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [program, setProgram] = useState({
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();
  const [isAgreed, setIsAgreed] = useState(false); // State to track checkbox
  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );

  const handleSkip = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!loggedInUser) {
        throw new Error("User not logged in");
      }

      setIsLoading(true);

      try {
        const membershipUuid = localStorage.getItem("MembershipUuid");
        if (membershipUuid) {
          getMembership(loggedInUser.uuid, membershipUuid)
            .then(async (membership) => {
              if (membership) {
                setMembership(membership);
                const newProgram = {
                  uuid: membership?.uuid || "",
                  name: membership?.name || "",
                  description: membership?.description || "",
                  isSelected: true,
                  brandLogo: membership?.imageURL || "",
                  brandColor: membership?.brandColor || "",
                  placesDetail: membership?.placesDetail || "",
                  brandPattern: membership?.brandPattern || "",
                };
                setProgram(newProgram);
                dispatch(programSlice.actions.addProgram(newProgram));
                localStorage.setItem("lastLogin", new Date().toISOString());
                if (membershipUuid) {
                  localStorage.removeItem("MembershipUuid");
                }
                localStorage.removeItem("phoneNumber");
                console.log(
                  "Got membership in the account and Redirecting to home",
                );
                navigate(`/home`);
                setIsLoading(false);
                return;
              }

              try {
                const referrer = localStorage.getItem("referrer");

                console.log(referrer);
                const joinPayload: {
                  membershipUuid: string;
                  shopperUuid: string;
                  referrerShopperUuid?: string;
                  locationId?: number;
                } = {
                  membershipUuid: membershipUuid,
                  shopperUuid: loggedInUser.uuid,
                  referrerShopperUuid: referrer
                    ? localStorage.getItem("referrer") || undefined
                    : undefined,
                };

                if (!referrer) {
                  joinPayload.locationId = localStorage.getItem("locationID")
                    ? Number(localStorage.getItem("locationID"))
                    : undefined;
                }

                await joinMembership(joinPayload);
                console.log("localID", localStorage.getItem("locationID"));
                console.log(
                  "localID number",
                  Number(localStorage.getItem("locationID")),
                );
                setIsLoading(false);
                localStorage.setItem("newMembership", "true");
                const newMembership = await getMembership(
                  loggedInUser.uuid,
                  membershipUuid,
                );
                setMembership(newMembership);
                setProgram({
                  uuid: newMembership?.uuid || "",
                  name: newMembership?.name || "",
                  description: newMembership?.description || "",
                  isSelected: true,
                  brandLogo: newMembership?.imageURL || "",
                  brandColor: newMembership?.brandColor || "",
                  placesDetail: newMembership?.placesDetail || "",
                  brandPattern: newMembership?.brandPattern || "",
                });
              } catch (e) {
                openNotification(
                  "error",
                  `Failed to join membership for the program`,
                  null,
                );
                if (membershipUuid) {
                  localStorage.setItem("MembershipUuid", membershipUuid);
                }
                navigate(`/`);
              }
              setIsLoading(false);
            })
            .catch(() => {
              openNotification("error", `Failed to load memberships`, null);
              localStorage.clear();
              if (membershipUuid) {
                localStorage.setItem("MembershipUuid", membershipUuid);
              }
              navigate(`/`);
            });
        } else {
          console.log("Not joinning membership and Redirecting to meed");
          localStorage.removeItem("phoneNumber");
          navigate(redirect || "/meed");
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Error while setting your name`, null);
      } finally {
        setIsLoading(false);
      }
      localStorage.removeItem("referrer");
    },
    [displayName, navigate, redirect, loggedInUser?.uuid],
  );

  const handleClick = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!loggedInUser) {
        throw new Error("User not logged in");
      }
      const membershipUuid = localStorage.getItem("MembershipUuid");

      if (!displayName) {
        //openNotification("warning", "Please enter your email.", null);
        if (membershipUuid) {
          navigate(`/home`);
        } else {
          const hasMembership = await getHaveMembership(loggedInUser.uuid);
          if (!hasMembership) {
            navigate(`/discovery`);
          } else {
            navigate(`/meed`);
          }
        }
        return;
      }

      setIsLoading(true);

      try {
        // Wait for both updateName and SendEmailVerification to complete
        const [response] = await Promise.all([
          updateEmail({
            shopperUuid: loggedInUser.uuid,
            email: displayName,
            phoneNumber: loggedInUser.phoneNumber,
            name: localStorage.getItem("userName") || "",
          }),
        ]);

        console.log(response);
        openNotification("success", `Your email updated successfully`, null);

        localStorage.removeItem("userName");

        if (membershipUuid) {
          try {
            const membership = await getMembership(
              loggedInUser.uuid,
              membershipUuid,
            );
            if (membership) {
              setMembership(membership);
              const newProgram = {
                uuid: membership?.uuid || "",
                name: membership?.name || "",
                description: membership?.description || "",
                isSelected: true,
                brandLogo: membership?.imageURL || "",
                brandColor: membership?.brandColor || "",
                placesDetail: membership?.placesDetail || "",
                brandPattern: membership?.brandPattern || "",
              };
              setProgram(newProgram);
              dispatch(programSlice.actions.addProgram(newProgram));
              localStorage.setItem("lastLogin", new Date().toISOString());
              localStorage.removeItem("MembershipUuid");
              navigate(`/home`);
            } else {
              const referrer = localStorage.getItem("referrer");

              console.log(referrer);
              await joinMembership({
                membershipUuid: membershipUuid,
                shopperUuid: loggedInUser.uuid,
                referrerShopperUuid:
                  (referrer && localStorage.getItem("referrer")) || "",
                ...(!referrer && {
                  locationId: localStorage.getItem("locationID")
                    ? Number(localStorage.getItem("locationID"))
                    : undefined,
                }),
              });
              localStorage.setItem("newMembership", "true");
              const newMembership = await getMembership(
                loggedInUser.uuid,
                membershipUuid,
              );
              setMembership(newMembership);
              setProgram({
                uuid: newMembership?.uuid || "",
                name: newMembership?.name || "",
                description: newMembership?.description || "",
                isSelected: true,
                brandLogo: newMembership?.imageURL || "",
                brandColor: newMembership?.brandColor || "",
                placesDetail: newMembership?.placesDetail || "",
                brandPattern: newMembership?.brandPattern || "",
              });
            }
            setIsLoading(false);
          } catch (e) {
            openNotification(
              "error",
              `Failed to join membership for the program`,
              null,
            );
            localStorage.setItem("MembershipUuid", membershipUuid);
            navigate(`/`);
            setIsLoading(false);
          }
        } else {
          localStorage.removeItem("phoneNumber");
          navigate(redirect || "/meed");
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Error while setting your name`, null);
        setIsLoading(false);
      } finally {
        localStorage.removeItem("referrer");
      }
    },
    [displayName, navigate, redirect, loggedInUser?.uuid],
  );

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/verify");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <fieldset
          disabled={isLoading}
          style={{ border: "none", background: "white" }}
        >
          <Container className="auth__wrap" variant="smartphone-column">
            <img
              className="auth__illustration"
              src={authCompletedImagePath}
              width="301.65"
              height="243"
              alt=""
            />
            <div className="auth__heading">Your Email Address</div>
            {/* <div className="opt_description">
              <div className="orange-circle"></div>
              Enjoy exclusive offers
            </div>
            <div className="opt_description">
              <div className="orange-circle"></div>
              Access special discount
            </div>
            <div className="opt_description">
              <div className="orange-circle"></div>
              Backup your account
            </div> */}
            <div className="opt_explain">
              For account recovery and system updates.
              <br />
              Recommended but not required.
            </div>
            <div className="auth__field">
              {/* <Label className="label">Email Address</Label> */}
              <AppInput
                tag="input"
                type="text"
                placeholder="Email"
                value={displayName}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
            </div>
            <div
              className={`auth__panel ${!displayName ? "auth__panel--disabled" : ""}`}
            >
              <div className="opt_explain_small" style={{ marginTop: 0 }}>
                Get updates and special offers from your programs via meed.
                Optional.
              </div>
              <Checkbox
                style={{ marginTop: 20 }}
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                disabled={!displayName}
              >
                I agree to receive email offers and updates from meed and
                programs I have joined on the meed platform.
              </Checkbox>
            </div>
            <div className="opt_explain_small">
              Note: We do not share your personally identifiable information
              such as email address or phone number with loyalty programs on
              meed.
            </div>
            <div className="auth__submit">
              <AppButton
                uniqueModifier="submit"
                fullWidth
                onClick={handleClick}
              >
                Continue
              </AppButton>

              <AppButton
                uniqueModifier="cancel"
                fullWidth
                onClick={handleClick}
              >
                Skip
              </AppButton>
            </div>
          </Container>
        </fieldset>
      </div>
    </div>
  );
}

export default EmailOpt;

// Add the following CSS to your stylesheet (auth.css)
