import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type SelfStampPayload = {
  shopperUuid: string;
  programUuid: string;
  locationId?: number;
};

type SelfStampResponse = {
  success: boolean;
  message: string;
  waitingTime: string;
};

export const selfStamp = async (
  payload: SelfStampPayload,
): Promise<SelfStampResponse> => {
  // Create a new payload object that conditionally includes referrerShopperUuid

  const response = await backendApi.post<
    any,
    AxiosResponse<SelfStampResponse>,
    SelfStampPayload
  >("v1/shopper/add/stamp", payload);

  console.log(payload);
  console.log("Self Stamp API Call");
  console.log(response.data);
  return response.data;
};
