import React from "react"
import "./wysiwyg.css";

type wysiwygProps = {
  children: React.ReactNode;
  className?: string; // Any mixed css class such as "auth__head-wrap" or "home" -- a node where container is added
}
function Wysiwyg({children, className}:wysiwygProps) {
  return (
    <div className={`${className && className + ' '}wysiwyg`}>{children}</div>
  );
}

export default Wysiwyg;
