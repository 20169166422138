import React from "react"
import "./card.css";

type cardProps = {
  children: React.ReactNode;
  className?: string; // Any mixed css class such as "auth__head-wrap" or "home" -- a node where container is added
}
function Card({children, className}:cardProps) {
  return (
    <div className={`${className && className + ' '}card`}>{children}</div>
  );
}

export default Card;
