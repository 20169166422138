import React from "react";
import BrandLogoPlaceholder from "../../assets/placeholders/placeHolder.png";
import "./discoveryGrid.css";
import FlipMove from "react-flip-move";
import { GetImage } from "../../services/api/getImage";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { discoveryProgramSlice } from "../../store/slices/discoveryData";
import { useDispatch } from "react-redux";

type Image = {
  programId: number;
  programUuid: string;
  programName: string;
  programDescription: string;
  programLocation: string;
  programLogoImageHash: string;
  productTypeDescription: string;
  programBrandColor: string;
  programLocationDetail: string;
  programBrandPattern: string;
  programJoiningAllowed: boolean;
};

type ImageGridProps = {
  images: Image[];
  currentTab: string;
};
function ImageGrid({ images, currentTab }: ImageGridProps) {
  const [imageSrcs, setImageSrcs] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const [discovery, setDiscovery] = useState<Image>({
    programId: -1,
    programUuid: "",
    programName: "",
    programDescription: "",
    programLocation: "",
    programLogoImageHash: "",
    productTypeDescription: "",
    programBrandColor: "",
    programLocationDetail: "",
    programBrandPattern: "",
    programJoiningAllowed: false,
  });

  const OnClickDiscovery = (image: Image) => {
    const updatedProgram = { ...image, isSelected: true };
    setDiscovery(updatedProgram);
    console.log(updatedProgram);
    dispatch(discoveryProgramSlice.actions.addProgram(updatedProgram));
    // console.log("Membership Clicked");
  };
  const filteredImages = images.filter(
    (image) =>
      currentTab === "All" || image.productTypeDescription === currentTab,
  );

  useEffect(() => {
    const downloadImages = async () => {
      const filteredImages = images.filter(
        (image) =>
          currentTab === "All" || image.productTypeDescription === currentTab,
      );

      for (let image of filteredImages) {
        try {
          const downloadedImage = await GetImage(image.programLogoImageHash);
          setImageSrcs((prevState) => ({
            ...prevState,
            [image.programUuid]: downloadedImage,
          }));
        } catch (error) {
          console.error("Failed to download image", error);
        }
      }
    };

    downloadImages();
  }, [images, currentTab]);

  return (
    <FlipMove typeName="div" className="image-grid">
      {filteredImages.map((image, index) => (
        <a
          href={"program"}
          className="image-grid__item"
          key={image.programUuid}
          onClick={() => OnClickDiscovery(image)}
        >
          <LazyLoadImage
            src={imageSrcs[image.programUuid] || BrandLogoPlaceholder}
            alt={image.programName}
            effect="blur"
          />
        </a>
      ))}
    </FlipMove>
  );
}

export default ImageGrid;
