import { useEffect } from "react";

const useViewportMeta = () => {
  useEffect(() => {
    let viewportMetaTag = document.querySelector(
      'meta[name="viewport"]',
    ) as HTMLMetaElement;
    if (!viewportMetaTag) {
      viewportMetaTag = document.createElement("meta");
      viewportMetaTag.name = "viewport";
      document.head.appendChild(viewportMetaTag);
    }
    viewportMetaTag.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
  }, []);
};

export default useViewportMeta;
