import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type UpdateEmailPayload = {
  shopperUuid: string;
  email: string;
  phoneNumber: string;
  name: string;
};

type UpdateEmailResponse = {};

export const updateEmail = async (
  payload: UpdateEmailPayload,
): Promise<UpdateEmailResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<UpdateEmailResponse>,
    UpdateEmailPayload
  >("v1/shopper/update/email", payload);
  console.log(response.data);
  return response.data;
};
