import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

// SMS OTP
type GetPhoneOtpPayload = {
  phoneNumber: string;
  deviceID: string;
  // turnstileToken: string;
};

type GetPhoneOtpResponse = { result: boolean };

export const getPhoneOtp = async (
  payload: GetPhoneOtpPayload,
): Promise<GetPhoneOtpResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<GetPhoneOtpResponse>,
    GetPhoneOtpPayload
  >("v4/auth/getPhoneOTP", payload);

  return response.data;
};

// Email OTP
type GetEmailOtpPayload = {
  email: string;
  deviceID: string;
  // turnstileToken: string;
};

type GetEmailOtpResponse = { result: boolean };

export const getEmailOtp = async (
  payload: GetEmailOtpPayload,
): Promise<GetEmailOtpResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<GetEmailOtpResponse>,
    GetEmailOtpPayload
  >("v2/auth/getOTP", payload);

  return response.data;
};
