import React, { useState } from "react";
import "./brief.css";
import { ReactComponent as GeoSymbol } from "../../assets/symbols/geo.svg";
import { ReactComponent as ClockOutlineSymbol } from "../../assets/symbols/clock-outline.svg";
import { ReactComponent as PhoneSymbol } from "../../assets/symbols/phone.svg";
import ParamLink from "../../ImportedComponents/ParamLink/param_link";

function Brief({
  children,
  isOpenning,
  nextTime,
  address,
  title,
  googleMapURL,
}: {
  children: React.ReactNode;
  isOpenning: boolean;
  nextTime: string;
  address: string;
  title: string;
  googleMapURL: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const OpenGoogleMap = () => {
    //Open googleMapURL in new tab
    console.log(googleMapURL);
    window.open(googleMapURL, "_blank");
  };

  return (
    <div className="brief">
      <h2 className="brief__heading">{title}</h2>
      <div className="brief__actions">
        <div onClick={OpenGoogleMap}>
          <ParamLink icon={GeoSymbol} url={"#"} hasMap>
            {address}
          </ParamLink>
        </div>
        {nextTime !== "N/A" && (
          <div onClick={toggleOpen}>
            <ParamLink icon={ClockOutlineSymbol} url="#" hasChevron>
              {isOpenning ? (
                <>
                  <span className="text-success">Open</span> . Close at{" "}
                  {nextTime}
                </>
              ) : (
                <>
                  <span className="text-danger">Closed</span>. Open at{" "}
                  {nextTime}
                </>
              )}
            </ParamLink>
            {isOpen && (
              <div
                style={{
                  marginLeft: "10%",
                  marginRight: "40%",
                  fontSize: "0.8rem",
                }}
              >
                {children}
              </div>
            )}
          </div>
        )}
        {/* <ParamLink icon={PhoneSymbol} url="#">
          29479553
        </ParamLink> */}
      </div>
    </div>
  );
}

export default Brief;
