import React, { useCallback, useEffect, useState } from "react";
import "./pwa-header.css";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import { ReactComponent as ScannerIcon } from "../../assets/symbols/scanner.svg";
import { ReactComponent as AddtoAppleIcon } from "../../assets/symbols/Add_to_Apple_Wallet_badge.svg";
import { ReactComponent as AddtoGoogleIcon } from "../../assets/symbols/Add_to_Google_Wallet_badge.svg";
import ProfileImage from "../../assets/temp/profile@3x.jpg";
import MeedLogoImage from "../../assets/icons/meed_full_logo.png";
import { NavLink } from "react-router-dom";
import { AppRoute } from "../../shared/const";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { authSlice } from "../../store/slices/auth";
import { logout } from "../../services/api/logout";

function PWAHeader() {
  const dispatch = useDispatch();
  const [os, setOs] = useState("");
  const onLogout = useCallback(async () => {
    await logout();
    dispatch(authSlice.actions.logout());
  }, [dispatch]);
  // useEffect(() => {
  //   const detectedOs = detectOS();
  //   setOs(detectedOs);
  // }, []);
  // function detectOS() {
  //   const platform = navigator.platform;
  //   if (platform.indexOf("Win") !== -1) return "Windows";
  //   if (platform.indexOf("Mac") !== -1) return "Mac OS";
  //   if (platform.indexOf("Linux") !== -1) return "Linux";
  //   if (platform.indexOf("iPhone") !== -1) return "iOS";
  //   if (platform.indexOf("Android") !== -1) return "Android";
  //   if (platform.indexOf("iPad") !== -1) return "iPad";
  //   return "Unknown";
  // }
  // const onClickAddToApple = () => {
  //   console.log("Add to Apple Wallet");
  // };

  return (
    <header className="pwa-header">
      <NavLink to={AppRoute.Meed} className="pwa-header__logo">
        <img className="pwa-header__emblem" src={MeedLogoImage} alt="" />
      </NavLink>
      <div className="pwa-header__brand-name">meed</div>
      {/* <NavLink to={AppRoute.QR} className="pwa-header__scan-handler">
        <ScannerIcon className="pwa-header__scanner" />
      </NavLink> */}

      {/* {(os === "iOS" || os === "iPad") && (
        <AddtoAppleIcon
          className={"add-to-apple"}
          onClick={onClickAddToApple}
        />
      )}

      {os === "Android" && (
        <AddtoGoogleIcon
          className={"add-to-apple"}
          onClick={onClickAddToApple}
        />
      )} */}

      {/* <Dropdown
        placement="bottomLeft"
        overlayStyle={{ minWidth: 150 }}
        menu={{
          items: [
            // {
            //   key: "profile",
            //   label: <NavLink to={AppRoute.Profile}>Profile</NavLink>,
            // },
            // { type: "divider" },
            {
              key: "logout",
              label: (
                <NavLink to="#" onClick={onLogout}>
                  Logout
                </NavLink>
              ),
            },
          ],
        }}
        trigger={["click"]}
      >
        <NavLink to="#" className="pwa-header__profile">
          <img className="pwa-header__user-pic" src={ProfileImage} alt="" />
        </NavLink>
      </Dropdown> */}
    </header>
  );
}

export default PWAHeader;
