import React from "react"
import "./back.css";
import {ReactComponent as ChevronLeftSymbol} from "../../assets/symbols/chevron-left.svg"
import {Link} from "react-router-dom";

const Back: React.FC<React.PropsWithChildren<{to: string}>> = ({children, to}) => {
  return (
    <Link className="back" to={to}>
      <ChevronLeftSymbol className="back__icon"/>
      {children}
    </Link>
  );
}

export default Back;
