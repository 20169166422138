import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authCompletedImagePath from "./content/auth-completed@3x.jpg";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { useNavigate } from "react-router-dom";
import openNotification from "../../Components/Antd/Notification/Notification";
import { useDispatch } from "react-redux";
import { PhoneInput, defaultCountries } from "react-international-phone";
import { authSlice } from "../../store/slices/auth"; // Adjust the import path as needed
import { Checkbox } from "antd";
import useViewportMeta from "../../hooks/useViewportMeta";

function AccountCreation() {
  useViewportMeta();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false); // State to track checkbox
  const dispatch = useDispatch();

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phoneNumber");
    if (storedPhoneNumber !== null) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  const handlePhoneLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      console.log("handlePhoneLogin triggered");

      setIsLoading(true);

      try {
        dispatch(authSlice.actions.setphoneNumberForLogin(phoneNumber));
        openNotification(
          "success",
          `An OTP has been delivered to the phone number ${phoneNumber}`,
          null,
        );
        console.log(`display Name: ${displayName}`);
        localStorage.setItem("userName", displayName);
        navigate({
          pathname: "/verify",
        });
      } catch (err) {
        console.error("Error during phone login:", err);
        openNotification("error", `Unable to process phone login`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [phoneNumber, dispatch, navigate, displayName],
  );

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/verify");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <fieldset disabled={isLoading} style={{ border: "none" }}>
          <Container className="auth__wrap" variant="smartphone-column">
            <p>
              Access all of your loyalty memberships in one place through meed.
              {/* Create your meed account which will be used every time you join a
              meed-powered loyalty program. By signing up today you will get
              extra perks from {localStorage.getItem("programName")}. */}
            </p>
            <div className="auth__panel">
              <div className="auth__field">
                <Label className="label">Phone Number</Label>
                <PhoneInput
                  defaultCountry="hk"
                  value={phoneNumber}
                  onChange={(value) => {
                    setPhoneNumber(value);
                  }}
                  countries={defaultCountries}
                  className="phone-input"
                />
              </div>
              <div className="auth__field">
                <Label className="label">Nickname</Label>
                <AppInput
                  tag="input"
                  type="text"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>

            <Checkbox
              style={{ marginTop: 20 }}
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
            >
              I agree to the&nbsp;
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Membership Terms
              </a>
              &nbsp;and&nbsp;
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              &nbsp;for information I share with meed from time to time.
            </Checkbox>

            <div className="auth__submit">
              <AppButton
                uniqueModifier="submit"
                fullWidth
                onClick={handlePhoneLogin}
                disabled={!isAgreed || !displayName || !phoneNumber} // Disable button if checkbox is not checked or name/phone number is empty
              >
                Get OTP via mobile
              </AppButton>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "gray" }}
              >
                Sign in
              </a>
            </div>
          </Container>
        </fieldset>
      </div>
    </div>
  );
}

export default AccountCreation;
