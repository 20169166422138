import React from "react"
import "./null.css";
import {ReactComponent as NullProfileSymbol} from "../../assets/symbols/null-profile.svg"

const Null: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <div className="null">
        <NullProfileSymbol className="null__icon"/>
        <div className="null__message">{children}</div>
    </div>
  );
}

export default Null;
