import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "./axiosIntercept";

const getLocations = async (programUuid: String) => {
  console.log("Before API call, Program UUID = " + programUuid);
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/business/location/` + programUuid,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    console.log("get location Program UUID after API call = " + programUuid);
    console.log("Locations fetched", response.data);
    return response.data;
  } catch (error) {
    if ((error as any).response) {
      const axiosError = error as any;
      console.error(
        "API Error:",
        axiosError.response.status,
        axiosError.response.data,
      );
    } else {
      const networkError = error as Error;
      console.error("Network Error:", networkError.message);
    }
    throw error;
  }
};

export default getLocations;
