import { AxiosResponse } from "axios";
import backendApi from "./backendApi";
import getLocations from "./getLocation"; // Import the getLocations function

type JoinMembershipPayload = {
  shopperUuid: string;
  membershipUuid: string;
  locationId?: number;
  referrerShopperUuid?: string;
};

type JoinMembershipResponse = {};

export const joinMembership = async (
  payload: JoinMembershipPayload,
): Promise<JoinMembershipResponse> => {
  // Destructure the optional parameters and the rest of the payload
  const { referrerShopperUuid, locationId, membershipUuid, ...restPayload } =
    payload;

  let finalLocationId = locationId;

  // If locationId is not provided, fetch locations using getLocations API
  if (!locationId) {
    try {
      const locations = await getLocations(membershipUuid);
      if (locations && locations.length > 0) {
        finalLocationId = locations[0].id; // Use the first location's ID
      } else {
        throw new Error("No locations found for the given membership UUID");
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      throw error;
    }
  }

  // Create the final payload conditionally including optional parameters
  const finalPayload: JoinMembershipPayload = {
    ...restPayload,
    membershipUuid,
    ...(referrerShopperUuid && { referrerShopperUuid }),
    ...(!referrerShopperUuid &&
      finalLocationId && { locationId: finalLocationId }),
  };

  const response = await backendApi.post<
    any,
    AxiosResponse<JoinMembershipResponse>,
    JoinMembershipPayload
  >("v2/membership/join", finalPayload);

  console.log(finalPayload);
  console.log("Join Membership API Call");
  console.log(response.data);

  // If the response is successful, delete the locationId key from localStorage
  if (response.status === 200) {
    localStorage.removeItem("locationID");
  }

  return response.data;
};
