export const GetImage = async (uuid: string): Promise<string> => {
  //console.log("GetImage", uuid);
  const url = process.env.REACT_APP_CDN_URL + `${uuid}.jpg`;
  //console.log(url);
  const response = await fetch(url);
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

/** 

import backendImageApi from "./backendImageApi";

export const GetImage = async (uuid: string): Promise<string> => {
  const response = await backendImageApi.get(`v1/s3/download?key=${uuid}.jpg`, {
    responseType: "blob",
  });
  const blob = new Blob([response.data], { type: "image/png" });
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

*/
