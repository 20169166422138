import React from "react";
import "./app_input.css";

type Props =
  | ({
      tag: "input";
      type?:
        | "text"
        | "email"
        | "password"
        | "tel"
        | "url"
        | "search"
        | "time"
        | "date"
        | "datetime-local"
        | "month"
        | "week"
        | "number"
        | "color";
    } & JSX.IntrinsicElements["input"])
  | ({
      tag: "select";
      options?: {
        value: string;
        label: string;
      }[];
    } & JSX.IntrinsicElements["select"])
  | ({ tag: "textarea" } & JSX.IntrinsicElements["textarea"]);

function AppInput(props: Props) {
  switch (props.tag) {
    case "textarea":
      return <textarea className="input" {...props} />;
    case "select":
      return (
        <select className="input" {...props}>
          {props.options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    default:
      return <input className="input" type={props.type ?? "text"} {...props} />;
  }
}

export default AppInput;
