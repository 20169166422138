import React from "react";
import "./app_button.css";

/**
 * @param {object} props
 * @param {Function} [props.onClick]
 * @param {"submit"|"button"} [props.type]
 * @param {string|undefined} [props.title]
 * @param {"secondary"|"faded"|"outline"|undefined} [props.variant]
 * @param {"submit"|"create-campaign"|"download-qr"|"skip"|"landing"|"cancel"|undefined} [props.uniqueModifier]
 * @param {boolean} [props.fullWidth]
 * @param {React.ReactNode|undefined} [props.leftIcon]
 * @param {React.ReactNode|undefined} [props.rightIcon]
 * @param {string|undefined} props.title
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 * @param {...any} [restProps] - Any other applicable props: disabled, title, etc.
 * @returns {React.ReactNode}
 */

function AppButton({
  children,
  variant,
  fullWidth,
  uniqueModifier,
  type = "button",
  className,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
  ...restProps
}) {
  const variantString = variant ? " button--" + variant : "";
  const uniqueModifierString = uniqueModifier
    ? " button--" + uniqueModifier
    : "";
  const fullWidthString = fullWidth ? " button--full-width" : "";
  const classString = className ? " " + className : "";

  return (
    <button
      className={
        "button" +
        variantString +
        uniqueModifierString +
        fullWidthString +
        classString
      }
      type={type}
      {...restProps}
    >
      {LeftIconComponent && <LeftIconComponent className="button__icon" />}
      {children}
      {RightIconComponent && <RightIconComponent className="button__icon" />}
    </button>
  );
}

export default AppButton;
