import React, { useState, useEffect, useRef } from "react";
import QrReader from "react-qr-reader";
import './QRScanner.css'

interface QRScannerProps {
    onDataScan: (data: string) => void;
  }

  const QRScanner: React.FC<QRScannerProps> = ({ onDataScan }) => {

    const videoRef = useRef<HTMLVideoElement>(null);

    const [isReaderActive, setIsReaderActive] = useState(true);
    
    const handleScan = (scanData: string | null) => {
        if (scanData) {
            onDataScan(scanData); // Sending data to parent component
            setIsReaderActive(false); // Disable reader after successful scan
        }
    };

    const handleError = (err: Error) => console.error(err);

    useEffect(() => {
        // Re-enable reader after a delay
        const timer = setTimeout(() => {
            setIsReaderActive(true);
        }, 3000); // Adjust the delay as needed

        return () => clearTimeout(timer);
    }, [isReaderActive]);

    useEffect(() => {
        if (videoRef.current) {
            // Now you can safely access video properties
            console.log(videoRef.current.videoWidth);
        }
    }, []);

    return (
        <div className="QR-container">
            {isReaderActive && (
                <QrReader
                    ref={videoRef}
                    facingMode={"environment"}
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100vw", height:"100vh"}}
                />
            )}
        </div>
    );
}

export default QRScanner;
