import axiosInstance from "../services/api/axiosIntercept";

const useEmailVerify = () => {
  const verifyEmail = async (
    token: string,
    email: string,
    turnstileToken: string,
  ) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/shopper/verify/email`, // Added a slash before `v1`
        {
          token,
          email,
          turnstileToken,
          bypassCloudflareCheckCode:
            "A5F983E7898649B776A820C73549568F43528AFF548240A98CE8E072491151FB",
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );
      return response; // Return the whole response object
    } catch (error) {
      console.error("Error verifying email:", error); // Log the error
      throw error; // Rethrow the error
    }
  };

  return { verifyEmail }; // Return the function
};

export default useEmailVerify;
