import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

export const CheckPhone = async (phone: string): Promise<any> => {
  const response = await backendApi.get<any, AxiosResponse<"", {}>>(
    `v1/shopper/check/${phone}`,
  );

  const data = response.data;
  // console.log(`v2/voucher/code/${uuid}`);
  // console.log(`response data: ${data}`);
  console.log(response.data);
  return response.data;
};
