import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type LoginEmailPayload = {
  email: string;
  otpCode: string;
  deviceID: string;
};

type LoginPhonePayload = {
  phoneNumber: string;
  otpCode: string;
  deviceID: string;
};

type LoginResponse = {
  shopper: {
    id: number;
    name: string;
    email: string;
    uuid: string;
    socialID: string | null;
    userName: string | null;
    companyLogoURL: string | null;
    legalEntityName: string | null;
    brandName: string | null;
    contactInformation: string | null;
    description: string | null;
    walletAddress: string | null;
    token: string;
    lastLoginDate: string;
    creationDate: string;
    lastUpdatedTime: string;
    encryptedEmail: string;
  };
  authenticated: true;
};

export const Emaillogin = async (
  payload: LoginEmailPayload,
): Promise<LoginResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<LoginResponse>,
    LoginEmailPayload
  >("v4/auth/shopper/pwa/login", payload);
  return response.data;
};

export const Phonelogin = async (
  payload: LoginPhonePayload,
): Promise<LoginResponse> => {
  console.log(payload);
  const response = await backendApi.post<
    any,
    AxiosResponse<LoginResponse>,
    LoginPhonePayload
  >("v4/auth/shopper/pwa/login", payload);
  console.log(response.data);
  return response.data;
};
