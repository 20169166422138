import React, { useState } from "react";
import "./tabs-alike-nav.css";

function getTextColor(backgroundColor: string) {
  // Convert the background color to RGB
  const color =
    backgroundColor.charAt(0) === "#"
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16) / 255;
  const g = parseInt(color.substring(2, 4), 16) / 255;
  const b = parseInt(color.substring(4, 6), 16) / 255;

  // Calculate the luminance of the background color
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return 'black' if the background color is bright, 'white' if it's dark
  return luminance > 0.5 ? "black" : "white";
}

function TabsAlikeNav({
  brandColor,
  onTabSelect,
}: {
  brandColor: string;
  onTabSelect: (tab: string) => void;
}) {
  const [activeTab, setActiveTab] = useState("All");

  const tabs = ["All", "New", "Expires Soon"];
  let background;
  if (brandColor) {
    background = {
      backgroundColor: brandColor,
      color: getTextColor(brandColor),
    };
  } else {
    background = { backgroundColor: "#FF7B0F" };
  }

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    onTabSelect(tab);
  };

  return (
    <div className="tabs-alike-nav hide-scrollbar">
      <div className="tabs-alike-nav__ribbon">
        {tabs.map((tab) => (
          <a
            key={tab}
            className={`tabs-alike-nav__link ${activeTab === tab ? "tabs-alike-nav__link--current" : ""}`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(tab);
            }}
          >
            <div
              className="tabs-alike-nav__inner"
              style={{
                backgroundColor: activeTab === tab ? brandColor : "#FF7B0F",
                color: activeTab === tab ? getTextColor(brandColor) : "black",
              }}
            >
              {tab}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default TabsAlikeNav;
