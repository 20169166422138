import React, { useState } from "react";
import "./dropdown.css"; // import the CSS file
import { ReactComponent as Chevrondown } from "../../assets/symbols/chevron-down-mini.svg";

function Dropdown({
  children,
  content,
}: {
  children: React.ReactNode;
  content: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    // <div>
    //   <button onClick={() => setIsOpen((isOpen) => !isOpen)}>
    //     Toggle Dropdown
    //   </button>

    //   <div className={`dropdown ${isOpen ? "open" : ""}`}>
    //     <p>Your content here</p>
    //     <p>More content...</p>
    //     {/* Add more content as needed */}
    //   </div>
    // </div>
    <div className="collapse">
      <h2 className="collapse__heading">
        <button
          className="collapse__handler"
          onClick={() => setIsOpen(!isOpen)}
        >
          {children}
          <Chevrondown className="collapse__angle" />
        </button>
      </h2>
      <div className={`collapse__dropdown ${isOpen ? "open" : ""}`}>
        {content}
      </div>
    </div>
  );
}

export default Dropdown;
