import React from "react";
import "./program-card.css";
import { ReactComponent as VoucherIcon } from "../../assets/symbols/voucher.svg";
import useDiscoveryProgram from "../../hooks/useDiscoveryProgram";

const ProgramCard: React.FC<{
  brandImage: string;
  brandName: string;
  catagory: string;
}> = ({ brandImage, brandName, catagory }) => {
  return (
    <div className="discovery-card">
      <div className="discovery-card__body">
        <div className="discovery-card__qr-preview">
          <img
            className="discovery-card__qr-code-pic"
            src={brandImage}
            alt="Userpic"
          />
        </div>
        <div className="discovery-card__info">
          <p className="discovery-brandName">{brandName}</p>
          <p className="catagory">{catagory}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
