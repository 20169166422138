import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

const openNotification = (
  type: NotificationType,
  title: string,
  description: string | null,
  durationInSeconds: number = 2,
) => {
  notification[type]({
    message: title,
    description,
    duration: durationInSeconds,
  });
};

export default openNotification;
