import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import TabsAlikeNav from "../../Components/tabs-alike-nav-discovery/tabs-alike-nav-discovery";
import ImageGrid from "../../Components/discoveryGrid/discoveryGrid";

import "../../shared/imports/_variables.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/deal.css";
import "../../Components/pwa-header/pwa-header.css";
import "./discovery.css";
import { AppRoute } from "../../shared/const";
import PWAHeader from "../../Components/pwa-header-meedpage/pwa-header-meed";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import openNotification from "../../Components/Antd/Notification/Notification";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import PopUpModal from "../../Components/Antd/Modal/Modal";
import { GetAllProgram } from "../../services/api/getAllPrograms";
import useGetVerified from "../../hooks/getVerified";

function Discovery() {
  const [selectedTab, setSelectedTab] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const loggedInUser = getLoggedInUser();
  const selectedProgram = getSelectedProgram();
  const [isLoading, setIsLoading] = useState(false);
  const [voucherCount, setVoucherCount] = useState(0);
  const getVerified = useGetVerified();

  useEffect(() => {
    getVerified();
  });
  const [images, setImages] = useState([
    {
      programId: -1,
      programUuid: "",
      programName: "",
      programDescription: "",
      programLocation: "",
      programLogoImageHash: "",
      productTypeDescription: "",
      programBrandColor: "",
      programLocationDetail: "",
      programBrandPattern: "",
      programJoiningAllowed: false,
    },
  ]);
  const handleGetMemberships = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await GetAllProgram();
      console.log(response);
      setImages(response);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load all program`, null);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetMemberships();
  }, [handleGetMemberships]);

  return (
    <>
      <div className="pwa-page">
        <PWAHeader />
        {showPopup && (
          <PopUpModal
            brandName={selectedProgram?.name}
            logo={selectedProgram?.brandLogo}
          />
        )}

        <Container className="discovery">
          <div className="discovery__filters">
            <TabsAlikeNav
              brandColor={selectedProgram?.brandColor || ""}
              onTabSelect={setSelectedTab}
            />
          </div>
        </Container>
        <div className="Discovery-Container-Title">
          {selectedTab === "All" ? (
            <h1>Featured Programs</h1>
          ) : (
            <h1>{selectedTab}</h1>
          )}
        </div>
        <div>
          <ImageGrid images={images} currentTab={selectedTab} />
        </div>
        <PWANav />
      </div>
    </>
  );
}

export default Discovery;
