import React from "react"
import "./container.css";

type containerProps = {
  children: React.ReactNode;
  className?: string; // Any mixed css class such as "auth__head-wrap" or "home" -- a node where container is added
  variant?: 'smartphone-column'; // variants such as "wide" or "full-width" are expected
}
function Container({children, className, variant}:containerProps) {
  let classString = className ? className + ' ': '';
  classString += 'container';
  classString+= variant ? ' container--' + variant : '';

  return (
    <div className={classString}>{children}</div>
  );
}

export default Container;
