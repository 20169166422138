import React from "react";

import { ReactComponent as Circle } from "../../assets/symbols/circle.svg";
import { ReactComponent as MeedLogo } from "../../assets/icons/meedLoading.svg";

export default function LoadingSpinner() {
  console.log("LoadingSpinner");
  return (
    <div
      style={{
        position: "fixed", // Use fixed positioning
        top: 0, // Start from the top of the page
        left: 0, // Start from the left of the page
        width: "100%", // Take up the full width
        height: "100%", // Take up the full height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 20000, // Make sure it's on top of other elements
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Add a semi-transparent background
      }}
    >
      <div
        style={{
          position: "relative",
          width: "180px",
          height: "180px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Circle
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            animation: "spin 2s linear infinite",
          }}
        />
        <MeedLogo
          style={{
            position: "relative",
            width: "50%",
            height: "50%",
          }}
        />
      </div>
    </div>
  );
}
