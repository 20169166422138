import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

// Update the return type to Promise<string[]>
export const GetProgramType = async (): Promise<string[]> => {
  const response = await backendApi.get<any, AxiosResponse<any>>(
    `v1/product-type`,
  );
  const descriptions = response.data.map((item: any) => item.description);
  console.log(descriptions);
  return descriptions; // Return the descriptions array
};
