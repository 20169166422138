import React from "react";
import "./param_link.css";
import { ReactComponent as ChevronDownMiniSymbol } from "../../assets/symbols/chevron-down-mini.svg";
import { ReactComponent as LocationOnMapSymbol } from "../../assets/symbols/location-on-map.svg";

const ParamLink: React.FC<
  React.PropsWithChildren<{
    url: string;
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string }
    >;
    hasChevron?: boolean;
    hasMap?: boolean;
  }>
> = ({ children, url, icon: IconComponent, hasChevron , hasMap}) => {
  return (
    <a className="param-link" href={url}>
      <IconComponent className="param-link__icon" />
      <span className="param-link__label">{children}</span>
      {hasChevron && <ChevronDownMiniSymbol className="param-link__chevron" />}
      {hasMap && <LocationOnMapSymbol className="param-link__map" />}
    </a>
  );
}

export default ParamLink;
