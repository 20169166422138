import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

export type GetVoucherResponse = {
  id: number;
  code: string;
  availableRedemptionCount: number;
  startDate: string;
  expiryDate: string;
  isValid: boolean;
  valueDescription: string;
  campaignName: string;
  voucherType: string;
  eventAddress: string;
  giveawayAddress: string;
  imageId: string;
  backgroundColor: string;
  unlockVisitThreshold: number;
  recordedVisits: number;
  recordedReferrals: number;
  referralRewardThreshold: number;
  memberSelfStamping: boolean;
  Program: {
    uuid: string;
    name: string;
    physicalAddress: string;
    brandColor: string;
    imageURL: string;
  };
}[];

export const GetVoucher = async (uuid: string): Promise<GetVoucherResponse> => {
  const response = await backendApi.get<
    any,
    AxiosResponse<GetVoucherResponse, {}>
  >(`v2/voucher/shopper/${uuid}`);

  const data = response.data;
  // console.log(`v2/voucher/shopper/${uuid}`);
  // console.log(`response data: ${data}`);
  console.log(response.data);
  return response.data;
};

// import { AxiosResponse } from "axios";
// import backendApi from "./backendApi";

// type GetVoucherResponse = [
//   {
//     voucherId: number;
//     code: string;
//     startDate: string;
//     expiryDate: string;
//     isValid: boolean;
//     valueDescription: string;
//     campaignName: string;
//     voucherType: string;
//     eventAddress: string;
//     giveawayAddress: string;
//     imageId: string;
//     backgroundColor: string;
//     unlockVisitThreshold: number;
//     recordedVisits: number;
//     businessName: string;
//     merchantId: number;
//     voucherCount: number;
//     physicalAddress: string;
//     brandLogoURL: string;
//     programUuid: string;
//   }
// ];
// //create a getMemberships function that takes in a payload of type GetMembershipsPayload and returns a promise of type GetMembershipsResponse and it is a get request
// export const GetVoucher = async (uuid: string): Promise<GetVoucherResponse> => {
//   const response = await backendApi.get<
//     any,
//     AxiosResponse<GetVoucherResponse, {}>
//   >(`v1/voucher/${uuid}`);
//   const data = response.data;
//   return {
//     voucherId: data.id,
//     code: data.code,
//     startDate: data.startDate,
//     expiryDate: data.expiryDate,
//     isValid: data.isValid,
//     valueDescription: data.valueDescription,
//     campaignName: data.campaignName,
//     voucherType: data.voucherType,
//     eventAddress: data.eventAddress,
//     giveawayAddress: data.giveawayAddress,
//     physicalAddress: data.Program.physicalAddress,
//     imageId: data.imageId,
//     backgroundColor: data.backgroundColor,
//     unlockVisitThreshold: data.unlockVisitThreshold,
//     recordedVisits: data.recordedVisits,
//     businessName: data.Program.name,
//     merchantId: data.Program.id,
//     brandLogoURL: data.Program.imageURL,
//     programUuid: data.Program.uuid,
//   };
// };
