import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type LogoutResponse = {};

export const logout = async (): Promise<LogoutResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<LogoutResponse>,
    {}
  >("v2/auth/shopper/pwa/logout", {});
  return response.data;
};
