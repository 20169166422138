import React, { useState, useRef, useEffect } from "react";
import { Button, Upload, message, Card, Spin, Modal } from "antd";
import {
  CameraOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./cameraApp.css";
import { uploadReceiptFile } from "../../services/api/uploadService";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import { useParams, useNavigate } from "react-router-dom";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { useSelector, useDispatch } from "react-redux";
import { programSlice } from "../../store/slices/data";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

const CameraApp: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useDispatch();
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null); // Save captured or selected image data
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCallbackModalVisible, setIsCallbackModalVisible] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState<string | null>(null);
  const shopperUuid = getLoggedInUser()?.uuid; // Replace with actual shopper UUID
  const navigate = useNavigate();
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();
  const [program, setProgram] = useState({
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });

  const { uuid, locationId } = useParams<{
    uuid: string;
    locationId: string;
  }>();

  // Initialize the camera
  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: isFrontCamera ? "user" : { exact: "environment" },
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      message.error("Error accessing the camera");
      console.error("Error accessing the camera: ", err);
    }
  };

  // Take a picture and display preview
  const takePicture = () => {
    const canvas = document.createElement("canvas");
    if (videoRef.current) {
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL("image/png");
      setImageDataUrl(dataUrl); // Save captured image data
      setPreviewSrc(dataUrl); // Set preview
      setIsModalVisible(true); // Show modal
    }
  };

  useEffect(() => {
    const membershipUuid = uuid;
    if (membershipUuid) {
      if (shopperUuid) {
        getMembership(shopperUuid, membershipUuid).then(async (membership) => {
          if (membership) {
            setMembership(membership);
            const newProgram = {
              uuid: membership?.uuid || "",
              name: membership?.name || "",
              description: membership?.description || "",
              isSelected: true,
              brandLogo: membership?.imageURL || "",
              brandColor: membership?.brandColor || "",
              placesDetail: membership?.placesDetail || "",
              brandPattern: membership?.brandPattern || "",
            };
            setProgram(newProgram);
            dispatch(programSlice.actions.addProgram(newProgram));
            if (membershipUuid) {
              localStorage.removeItem("MembershipUuid");
            }
          }
        });
      }
    }
  }, [shopperUuid]);

  const backToMeed = () => {
    navigate("/home");
  };

  // Convert data URL to array buffer
  const dataUrlToArrayBuffer = (dataUrl: string): Uint8Array => {
    const base64 = dataUrl.split(",")[1];
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  // Upload image to the server
  const uploadImage = async () => {
    if (!imageDataUrl) {
      message.error("Please take a photo or select an image");
      return;
    }

    setLoading(true); // Start uploading
    const buffer = dataUrlToArrayBuffer(imageDataUrl);

    try {
      if (!shopperUuid) {
        message.error("User is not logged in");
        setLoading(false);
        return;
      }
      const sha = await uploadReceiptFile(
        buffer,
        uuid,
        shopperUuid,
        locationId,
      );
      setCallbackMessage("Image uploaded successfully with SHA: " + sha);
      setIsCallbackModalVisible(true);
    } catch (error) {
      setCallbackMessage("Error uploading image");
      setIsCallbackModalVisible(true);
    } finally {
      setLoading(false); // Finish uploading
    }
  };

  // Switch between front and back cameras
  const switchCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  // Handle file selection from local gallery
  const handleFileChange = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        setImageDataUrl(dataUrl); // Save the selected image data
        setPreviewSrc(dataUrl); // Set selected image as preview
        setIsModalVisible(true); // Show modal
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    } else {
      message.error("No file selected."); // Show error message
      console.error("No file selected or file is not a Blob.");
    }
  };

  useEffect(() => {
    startCamera();
  }, [isFrontCamera]);

  return (
    <div className="camera-app">
      <Card className="camera-card" hoverable>
        <div className="top-button-group">
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={switchCamera}
            className="animated-button"
          >
            Camera
          </Button>
        </div>
        <video ref={videoRef} className="video-stream" autoPlay playsInline />
        <div className="button-group">
          {/* <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={switchCamera}
            className="animated-button"
          >
            Switch Camera
          </Button> */}

          <Upload
            name="file"
            showUploadList={false}
            customRequest={({ file }) => handleFileChange(file as File)} // Pass file directly
            accept="image/*"
          >
            <Button className="gallery-button" icon={<UploadOutlined />}>
              Select from Gallery
            </Button>
          </Upload>
          <Button
            type="primary"
            icon={<CameraOutlined />}
            onClick={takePicture}
            className="animated-button"
          >
            Scan Receipt
          </Button>
        </div>
        <div className="bottom-button-group">
          <Button
            type="primary"
            onClick={backToMeed}
            className="back-to-meed-button"
          >
            Back to meed
          </Button>
        </div>
      </Card>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="camera-modal-content">
          {loading ? (
            <Spin size="large" className="loading-spinner" />
          ) : (
            previewSrc && (
              <img src={previewSrc} alt="" className="preview-image animated" />
            )
          )}

          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={uploadImage}
            disabled={!previewSrc || loading}
            className="upload-button animated-button"
          >
            Upload Receipt
          </Button>
        </div>
      </Modal>

      <Modal
        visible={isCallbackModalVisible}
        footer={null}
        onCancel={() => setIsCallbackModalVisible(false)}
      >
        <div className="callback-modal-content">
          {/* <p>{callbackMessage}</p> */}
          <p>Thank you for your patience. Your receipt has been scanned.</p>
          <Button
            type="primary"
            onClick={() => setIsCallbackModalVisible(false)}
            className="animated-button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CameraApp;
