import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authCompletedImagePath from "./content/auth-completed@3x.jpg";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { useLocation, useNavigate } from "react-router-dom";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import openNotification from "../../Components/Antd/Notification/Notification";
import { updateName } from "../../services/api/updateName";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { useSelector, useDispatch } from "react-redux";
import { programSlice } from "../../store/slices/data";
import { joinMembership } from "../../services/api/joinMembership";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

function Name() {
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();
  const [displayName, setDisplayName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [program, setProgram] = useState({
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();

  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );

  const handleClick = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!loggedInUser) {
        throw new Error("User not logged in");
      }

      if (!displayName) {
        openNotification("warning", "Please enter your Display Name.", null);
        return;
      }
      localStorage.setItem("UserName", displayName);
      console.log(displayName);
      setIsLoading(true);

      try {
        const response = await updateName({
          shopperUuid: loggedInUser.uuid,
          name: displayName,
        });

        console.log(response);
        openNotification("success", `Your name updated successfully`, null);
        const membershipUuid = localStorage.getItem("MembershipUuid");
        if (membershipUuid) {
          getMembership(loggedInUser.uuid, membershipUuid)
            .then(async (membership) => {
              if (membership) {
                setMembership(membership);
                const newProgram = {
                  uuid: membership?.uuid || "",
                  name: membership?.name || "",
                  description: membership?.description || "",
                  isSelected: true,
                  brandLogo: membership?.imageURL || "",
                  brandColor: membership?.brandColor || "",
                  placesDetail: membership?.placesDetail || "",
                  brandPattern: membership?.brandPattern || "",
                };
                setProgram(newProgram);
                dispatch(programSlice.actions.addProgram(newProgram));
                localStorage.setItem("lastLogin", new Date().toISOString());
                if (membershipUuid) {
                  localStorage.removeItem("MembershipUuid");
                }

                setIsLoading(false);
                return;
              }

              try {
                await joinMembership({
                  membershipUuid: membershipUuid,
                  shopperUuid: loggedInUser.uuid,
                  referrerShopperUuid: localStorage.getItem("referrer") || "",
                  locationId: localStorage.getItem("locationID")
                    ? Number(localStorage.getItem("locationID"))
                    : undefined,
                });
                setIsLoading(false);
                localStorage.setItem("newMembership", "true");
                const newMembership = await getMembership(
                  loggedInUser.uuid,
                  membershipUuid,
                );
                setMembership(newMembership);
                setProgram({
                  uuid: newMembership?.uuid || "",
                  name: newMembership?.name || "",
                  description: newMembership?.description || "",
                  isSelected: true,
                  brandLogo: newMembership?.imageURL || "",
                  brandColor: newMembership?.brandColor || "",
                  placesDetail: newMembership?.placesDetail || "",
                  brandPattern: newMembership?.brandPattern || "",
                });
              } catch (e) {
                openNotification(
                  "error",
                  `Failed to join membership for the program`,
                  null,
                );
                if (membershipUuid) {
                  localStorage.setItem("MembershipUuid", membershipUuid);
                }
                navigate(`/`);
              }
              setIsLoading(false);
            })
            .catch(() => {
              openNotification("error", `Failed to load memberships`, null);
              localStorage.clear();
              if (membershipUuid) {
                localStorage.setItem("MembershipUuid", membershipUuid);
              }
              navigate(`/`);
            });
        } else {
          if (loggedInUser?.email) {
            navigate(`/home`);
          } else {
            navigate(`/emailOpt`);
          }
          // navigate(redirect || "/meed");
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Error while setting your name`, null);
      } finally {
        setIsLoading(false);
      }
      localStorage.removeItem("referrer");
    },
    [displayName, navigate, redirect, loggedInUser?.uuid],
  );

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/verify");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <fieldset disabled={isLoading} style={{ border: "none" }}>
          <Container className="auth__wrap" variant="smartphone-column">
            <img
              className="auth__illustration"
              src={authCompletedImagePath}
              width="260"
              height="243"
              alt=""
            />
            <div className="auth__heading">What’s your name?</div>
            <div className="auth__panel">
              <div className="auth__field">
                <Label className="label">Display name</Label>
                <AppInput
                  tag="input"
                  type="text"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="auth__submit">
              <AppButton
                uniqueModifier="submit"
                fullWidth
                onClick={handleClick}
              >
                Next Step
              </AppButton>
            </div>
          </Container>
        </fieldset>
      </div>
    </div>
  );
}

export default Name;
