import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type AddToAppleWalletPayload = {
  shopperUUID: string;
  programUUID: string;
};

type AddToAppleWalletResponse = {};

export const getAddToAppleWallet = async (
  payload: AddToAppleWalletPayload,
): Promise<void> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<ArrayBuffer>,
    AddToAppleWalletPayload
  >("v2/passes/apple", payload, { responseType: "arraybuffer" });

  console.log(payload);
  const blob = new Blob([response.data], {
    type: "application/vnd.apple.pkpass",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "pass.pkpass");
  document.body.appendChild(link);
  link.click();
};
