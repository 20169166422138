import React, { useState } from "react";
import "./circle.css";
import { ReactComponent as WalletIcon } from "../../assets/symbols/wallet.svg";
import { ReactComponent as Profile } from "../../assets/symbols/profileIcon.svg";
import { ReactComponent as QrCode } from "../../assets/symbols/qrCode.svg";
import { ReactComponent as MeedIcon } from "../../assets/symbols/meed logo-just icon white.svg";
import { ReactComponent as DiscoveryIcon } from "../../assets/symbols/discovery.svg";
import LoadingSpinner from "../../Components/loading/loading";

export const CircleMenuComponent = () => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onClickToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`circlemenu${isActive ? " active" : ""}`}>
        <div className="toggle" onClick={onClickToggle}>
          {<MeedIcon style={{ height: 30 }} />}
        </div>
        <li data-index="0">
          <a href="discovery" onClick={() => setIsLoading(true)}>
            {<DiscoveryIcon />}
          </a>
        </li>
        <li data-index="1">
          <a href="profile" onClick={() => setIsLoading(true)}>
            {<Profile />}
          </a>
        </li>
        <li data-index="2">
          <a href="meed" onClick={() => setIsLoading(true)}>
            {<WalletIcon />}
          </a>
        </li>
      </div>
    </>
  );
};
