import React, { useState, useEffect, useCallback } from "react";
import "./tabs-alike-nav-discovery.css";
import { GetProgramType } from "../../services/api/getProgramType";
import openNotification from "../../Components/Antd/Notification/Notification";

function getTextColor(backgroundColor: string) {
  // Convert the background color to RGB
  const color =
    backgroundColor.charAt(0) === "#"
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16) / 255;
  const g = parseInt(color.substring(2, 4), 16) / 255;
  const b = parseInt(color.substring(4, 6), 16) / 255;

  // Calculate the luminance of the background color
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return 'black' if the background color is bright, 'white' if it's dark
  return luminance > 0.5 ? "black" : "white";
}

function TabsAlikeNavDiscovery({
  brandColor,
  onTabSelect,
}: {
  brandColor: string;
  onTabSelect: (tab: string) => void;
}) {
  const [activeTab, setActiveTab] = useState("All");
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  /*
  const tabs = [
    "All",
    "Children",
    "Fashion & Accessories",
    "Food & Beverage",
    "Health & Beauty",
    "Home & Living",
    "Leisure & Entertainment",
    "Services",
    "Travel & Hospitality",
  ];
  */
  const [tabs, setTab] = useState([""]);
  const handleGetMemberships = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await GetProgramType();
      console.log(response);
      setTab(["All", ...response]);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load all program`, null);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetMemberships();
  }, [handleGetMemberships]);

  let background;
  if (brandColor) {
    background = {
      backgroundColor: brandColor,
      color: getTextColor(brandColor),
    };
  } else {
    background = { backgroundColor: "#FF7B0F" };
  }

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    onTabSelect(tab);
  };
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX; //scroll-slow
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  return (
    <div
      className="tabs-alike-nav hide-scrollbar"
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div className="tabs-alike-nav__ribbon">
        {tabs.map((tab) => {
          const isActive = activeTab === tab;
          const linkClass = `tabs-alike-nav__link ${isActive ? "tabs-alike-nav__link--current" : ""}`;
          const innerClass = `tabs-alike-nav__inner ${isActive ? "tabs-alike-nav__inner--active" : ""}`;

          return (
            <a
              key={tab}
              className={linkClass}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleTabClick(tab);
              }}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  e.preventDefault();
                }
              }}
            >
              <div className={innerClass}>{tab}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
}
export default TabsAlikeNavDiscovery;
