import { v4 as uuidv4 } from "uuid";

export function getDeviceUuid() {
  // Attempt to get the UUID from the localStorage
  let deviceId = localStorage.getItem("device-uuid");

  // If it doesn't exist, create one and set the localStorage
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device-uuid", deviceId);
  }

  return deviceId;
}
