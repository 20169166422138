import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../shared/imports/_variables.css";
import "./emailVerify.css";
import LogoImagePath from "../auth/content/logo@3x.png";
import Container from "../../Components/container/container";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import openNotification from "../../Components/Antd/Notification/Notification";
import { useDispatch } from "react-redux";
import { getEmailOtp } from "../../services/api/getOtp";
import Capcha from "../../Components/capcha/capcha";
import { authSlice } from "../../store/slices/auth";
import useEmailVerify from "../../hooks/emailVerify";
import successImage from "../../assets/icons/success.png";
import failImage from "../../assets/icons/fail.png";

function EmailVerify() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token") || "";
  const email = queryParams.get("email") || "";
  console.log("Email:", email);
  console.log("Token:", token);
  const [status, setStatus] = useState<string | null>("");

  const turnstileRef = useRef<TurnstileInstance>(null);
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);

  //   const [isLoading, setIsLoading] = useState(false);
  const { verifyEmail } = useEmailVerify();

  //   const { verify, isLoading } = useEmailVerify(email, token, "");

  const handleTurnstileSuccess = async () => {
    const turnstileToken = turnstileRef.current?.getResponse();
    if (turnstileToken) {
      console.log("Turnstile Token:", turnstileToken);
      try {
        const res = await verifyEmail(token, email, turnstileToken);
        console.log("API Response:", res);
        if (res.data.success === true) {
          setStatus("Email successfully verified!");
          console.log("Email verification successful");
        } else {
          setStatus("Email not verified!");
          console.log("Email verification failed");
        }
      } catch (error) {
        throw error;
      }
      //   verifyEmail(token, email, turnstileToken);
      //   console.log("Email verification successful");
    } else {
      console.log("Captcha validation failed");
    }
  };

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <img
              className="emailVerIcon"
              src={LogoImagePath}
              width="199"
              height="62"
              alt=""
            />
          </Container>
        </div>
        <Container className="auth__wrap" variant="smartphone-column">
          <form className="auth__form">
            <fieldset className="auth__fieldset">
              <div className="auth__main-heading">Email verification</div>
              {status ===
              "Your email was verified. You can continue using the application." ? (
                <div className="status_logo">
                  <img
                    src={successImage}
                    alt="Success"
                    style={{
                      marginTop: "20px",
                      width: "20%",
                      height: "auto",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  />
                </div>
              ) : status === "Email not verified!" ? (
                <div className="status_logo">
                  <img
                    src={failImage}
                    alt="Fail"
                    style={{
                      marginTop: "20px",
                      width: "20%",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </div>
              ) : (
                <div className="auth__panel">
                  <div className="auth__field">
                    <Capcha>
                      <Turnstile
                        siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY ?? ""}
                        options={{
                          appearance: "interaction-only",
                        }}
                        onError={() => console.log("captcha validation failed")}
                        onBeforeInteractive={() => {
                          setTurnstileRequireInteractive(true);
                        }}
                        ref={turnstileRef}
                        onSuccess={handleTurnstileSuccess}
                      />
                    </Capcha>
                  </div>
                </div>
              )}
              <p>{status}</p>
            </fieldset>
          </form>
        </Container>
      </div>
    </div>
  );
}

export default EmailVerify;
