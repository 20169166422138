import React, { useEffect, useState } from "react";
import "../../shared/imports/_variables.css";
import "./venue.css";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import Brief from "../../Components/brief/brief";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import { getGeocode, getLatLng } from "use-places-autocomplete";

function getOpeningTimes({ places }: any) {
  const openingTimes = places?.[0]?.regularOpeningHours?.weekdayDescriptions;

  return openingTimes?.map((time: string, index: number) => {
    const splitIndex = time.search(/\d/); // Find the index of the first digit
    const day = time.slice(0, splitIndex).trim();
    const hours = time.slice(splitIndex).trim();

    // Split the hours into opening and closing times
    const [openingTime, closingTime] = hours.split("–").map(formatTime);

    return (
      <div
        key={index}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ marginRight: "10px" }}>{day}</span>
        <span>{`${openingTime} – ${closingTime}`}</span>
      </div>
    );
  });
}

function getOpeningStatus({ places }: any) {
  return places?.[0]?.regularOpeningHours?.openNow || false;
}

function getNextTime({ places }: any, isOpening: boolean) {
  const openingHours = places?.[0]?.regularOpeningHours?.periods;

  if (!openingHours) {
    return "N/A";
  }

  const currentTime = new Date();
  let nextTime = null;

  if (isOpening) {
    // Find the closing time for the current day
    const closingTimeToday = openingHours.find(
      ({ close }: { close: { day: number } }) =>
        close && close.day === currentTime.getDay(),
    );
    if (closingTimeToday?.close) {
      nextTime = new Date();
      nextTime.setHours(
        closingTimeToday.close.hour,
        closingTimeToday.close.minute,
      );
    }
  } else {
    // Find the opening time for the next day
    const openingTimeTomorrow = openingHours.find(
      ({ open }: { open: { day: number } }) =>
        open && open.day === (currentTime.getDay() + 1) % 7,
    );
    if (openingTimeTomorrow?.open) {
      nextTime = new Date();
      nextTime.setDate(nextTime.getDate() + 1);
      nextTime.setHours(
        openingTimeTomorrow.open.hour,
        openingTimeTomorrow.open.minute,
      );
    }
  }

  return nextTime
    ? nextTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "N/A";
}

function formatTime(time: string) {
  const [hour, minutes] = time.trim().split(":");
  return `${hour.padStart(2, "0")}:${minutes}`;
}

function Venue() {
  const selectedProgram = getSelectedProgram();
  const data = JSON.parse(selectedProgram?.placesDetail || "{}");
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [center, setCenter] = useState({
    lng: 80.6337262, // default longitude
    lat: 7.2905715, // default latitude
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });

  const mapContainerStyle = {
    width: "100%",
    height: "220px",
  };

  useEffect(() => {
    if (isLoaded && data.places?.[0]?.id) {
      // getGeocode({ placeId: data.places[0].id })
      //   .then((results) => getLatLng(results[0]))
      //   .then((latLng) => {
      //     const newCenter = { lat: latLng.lat, lng: latLng.lng };
      //     setCenter(newCenter);
      //     setMarkerPosition(newCenter);
      //   })
      //   .catch((error) => {
      //     console.error("Error getting geocode: ", error);
      //   });
      console.log("change");
    }
  }, [isLoaded, data]);

  return (
    <Container className="pwa-page">
      <PWAHeader />
      <div className="venue container">
        <h1 className="venue__heading">Location</h1>
        <div className="venue__map">
          <div
            style={{
              height: "220px",
              background: "purple",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={19}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            )}
          </div>
        </div>
        <div className="venue__list">
          <Brief
            title={selectedProgram?.name || ""}
            address={data.places?.[0]?.shortFormattedAddress || ""}
            isOpenning={getOpeningStatus(data)}
            nextTime={getNextTime(data, getOpeningStatus(data))}
            googleMapURL={`https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`}
          >
            {getOpeningTimes(data)}
          </Brief>
        </div>
      </div>
      <PWANav />
    </Container>
  );
}

export default Venue;
