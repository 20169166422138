import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

export type GetAllProgramResponse = {
  programId: number;
  programUuid: string;
  programName: string;
  programDescription: string;
  programLocation: string;
  programLogoImageHash: string;
  productTypeDescription: string;
  programBrandColor: string;
  programLocationDetail: string;
  programBrandPattern: string;
  programJoiningAllowed: boolean;
}[];

export const GetAllProgram = async (): Promise<GetAllProgramResponse> => {
  const response = await backendApi.get<
    any,
    AxiosResponse<GetAllProgramResponse, {}>
  >(`v1/program/discoverable`);

  const data = response.data;

  return response.data;
};
