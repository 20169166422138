import React from "react"
import "./capcha.css";

const Capcha: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <div className="capcha">
      {children}
    </div>
  );
}

export default Capcha;
