import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type UpdateNamePayload = {
  shopperUuid: string;
  programUuid?: string;
  name?: string;
  emailOptIn?: boolean;
  email?: string;
};

type UpdateNameResponse = {};

export const updateName = async (
  payload: UpdateNamePayload,
): Promise<UpdateNameResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<UpdateNameResponse>,
    UpdateNamePayload
  >("v1/shopper/update/profile", payload);
  console.log(response.data);
  return response.data;
};
