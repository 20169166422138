import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type User = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  uuid: string;
};
type AuthState = {
  user?: User;
  emailForLogin?: string;
  isLoginTypeNumber: boolean;
  phoneNumber?: string;
};

const initialState: AuthState = {
  isLoginTypeNumber: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearEmailForLogin(state) {
      state.emailForLogin = undefined;
    },
    setEmailForLogin(state, action: PayloadAction<string>) {
      state.emailForLogin = action.payload;
    },
    setIsLoginTypeNumber(state, action: PayloadAction<boolean>) {
      state.isLoginTypeNumber = action.payload;
    },
    clearphoneNumberForLogin(state) {
      state.phoneNumber = undefined;
    },
    setphoneNumberForLogin(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    login(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    logout(state) {
      state.user = undefined;
    },
  },
});

export default authSlice.reducer;
