import React, { useCallback, useEffect, useState } from "react";
import "./pwa-header.css";
import BrandLogoPlaceholder from "../../assets/icons/success.png";
import { ReactComponent as AddtoAppleIcon } from "../../assets/symbols/Add_to_Apple_Wallet_badge.svg";
import { ReactComponent as AddtoGoogleIcon } from "../../assets/symbols/Add_to_Google_Wallet_badge.svg";
import { NavLink } from "react-router-dom";
import { AppRoute } from "../../shared/const";
import { useDispatch } from "react-redux";
import { authSlice } from "../../store/slices/auth";
import { logout } from "../../services/api/logout";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import { GetImage } from "../../services/api/getImage";
import { getAddToAppleWallet } from "../../services/api/getAddToAppleWallet";
import { getAddToGoogleWallet } from "../../services/api/getGooglePass";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import LoadingSpinner from "../../Components/loading/loading";

function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`
    : null;
}

function PWAHeader() {
  const dispatch = useDispatch();
  const loggedInUser = getLoggedInUser();
  const [os, setOs] = useState("");
  const selectedProgram = getSelectedProgram();
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(BrandLogoPlaceholder);

  function detectOS() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Android detection
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
    return "unknown";
  }
  const onClickAddToApple = async () => {
    setLoading(true); // Show the spinner
    await getAddToAppleWallet({
      shopperUUID: loggedInUser?.uuid ?? "",
      programUUID: selectedProgram?.uuid ?? "",
    });
    setLoading(false); // Hide the spinner
    // console.log("Add to Apple Wallet");
  };

  const onClickAddToGoogle = async () => {
    setLoading(true); // Show the spinner
    const pass = getAddToGoogleWallet({
      shopperUUID: loggedInUser?.uuid ?? "",
      programUUID: selectedProgram?.uuid ?? "",
    });
    setLoading(false); // Hide the spinner
    // console.log("Add to Google Wallet");
  };
  useEffect(() => {
    const downloadImage = async () => {
      try {
        const downloadedImage = await GetImage(
          selectedProgram?.brandLogo ?? "",
        );
        setImageSrc(downloadedImage);
        // console.log(downloadedImage);
      } catch (error) {
        console.error("Failed to download image", error);
      }
    };
    downloadImage();
    setOs(detectOS);
  }, [selectedProgram?.brandLogo]);

  return (
    <header className="pwa-header">
      {loading && <LoadingSpinner />}
      <NavLink to={AppRoute.Home} className="pwa-header__logo">
        <img
          className="pwa-header__emblem"
          src={selectedProgram?.brandLogo ? imageSrc : BrandLogoPlaceholder}
          alt=""
        />
      </NavLink>
      <div className="pwa-header__brand-name">
        {selectedProgram?.name || "meed"}
      </div>
      {/* <NavLink to={AppRoute.QR} className="pwa-header__scan-handler">
        <ScannerIcon className="pwa-header__scanner" />
      </NavLink> */}
      {os === "iOS" && (
        <AddtoAppleIcon
          className={"add-to-apple"}
          onClick={onClickAddToApple}
        />
      )}
      {os === "Android" && (
        <AddtoGoogleIcon
          className={"add-to-apple"}
          onClick={onClickAddToGoogle}
        />
      )}

      {/* <Dropdown
        placement="bottomLeft"
        overlayStyle={{ minWidth: 150 }}
        menu={{
          items: [
            // {
            //   key: "profile",
            //   label: <NavLink to={AppRoute.Profile}>Profile</NavLink>,
            // },
            // { type: "divider" },
            {
              key: "logout",
              label: (
                <NavLink to="#" onClick={onLogout}>
                  Logout
                </NavLink>
              ),
            },
          ],
        }}
        trigger={["click"]}
      >
        <NavLink to="#" className="pwa-header__profile">
          <img className="pwa-header__user-pic" src={ProfileImage} alt="" />
        </NavLink>
      </Dropdown> */}
    </header>
  );
}

export default PWAHeader;
