import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type AddToGoogleWalletPayload = {
  shopperUUID: string;
  programUUID: string;
};

type AddToGoogleWalletResponse = {};

export const getAddToGoogleWallet = async (
  payload: AddToGoogleWalletPayload,
): Promise<void> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<{ link: string }>,
    AddToGoogleWalletPayload
  >("v2/passes/google", payload);

  console.log(payload);
  const url = response.data.link;
  console.log(url);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
};
