import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DiscoveryProgram = {
  programId: number;
  programUuid: string;
  programName: string;
  programDescription: string;
  programLocation: string;
  programLogoImageHash: string;
  productTypeDescription: string;
  programBrandColor: string;
  programLocationDetail: string;
  programBrandPattern: string;
  programJoiningAllowed: boolean;
};

// Define a type for the slice state
interface DiscoveryProgramState {
  discoveryProgram: DiscoveryProgram | null;
}

// Define the initial state using that type
const initialState: DiscoveryProgramState = {
  discoveryProgram: null,
};

export const discoveryProgramSlice = createSlice({
  name: "discoveryProgram",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addProgram: (state, action: PayloadAction<DiscoveryProgram>) => {
      // console.log("Adding program to store");
      // console.log(action.payload);
      // Replace the current program with the new one
      state.discoveryProgram = action.payload;
    },
    removeProgram: (state) => {
      // Clear the current program
      state.discoveryProgram = null;
    },
  },
});

export default discoveryProgramSlice.reducer;
