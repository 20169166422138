export enum AppRoute {
  Home = "/home",
  HomeItem = "/home-item",
  Location = "/location",
  About = "/about",
  Meed = "/meed",
  QR = "/qr",
  Profile = "/profile",
  Discovery = "/discovery",
}
