import React, { useState, useEffect } from "react";
import { Button, Checkbox, Modal } from "antd";
import "./Modal.css";
import { getAddToAppleWallet } from "../../../services/api/getAddToAppleWallet";
import { getAddToGoogleWallet } from "../../../services/api/getGooglePass";
import getSelectedProgram from "../../../hooks/useSelectedProgram";
import getLoggedInUser from "../../../hooks/useLoggedInUser";
import AddtoAppleIcon from "../../../assets/placeholders/addToAppleWallet2.png";
import AddtoGoogleIcon from "../../../assets/placeholders/addToGoogleWallet2.png";
import { ReactComponent as SkipIcon } from "../../../assets/symbols/Skip.svg";
import { GetImage } from "../../../services/api/getImage";
import BrandLogoPlaceholder from "../../../assets/placeholders/placeHolder.png";
import LoadingSpinner from "../../../Components/loading/loading";
import type { CheckboxProps } from "antd";
import { updateName } from "../../../services/api/updateName";

const PopUpModal: React.FC<{ logo: any; brandName: any }> = ({
  logo,
  brandName,
}) => {
  const loggedInUser = getLoggedInUser();
  const selectedProgram = getSelectedProgram();
  const [loading, setLoading] = useState(false);
  const [logoSrc, setLogoSrc] = useState<string | null>(BrandLogoPlaceholder);
  const [os, setOs] = useState("");
  const [open, setOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const title = "Welcome to " + brandName;

  useEffect(() => {
    const detectedOs = detectOS();
    console.log("detectedOs", detectedOs);
    setOs(detectedOs);
  }, []);

  useEffect(() => {
    const downloadImage = async (url: string) => {
      try {
        return await GetImage(url);
      } catch (error) {
        console.error("Failed to download image", error);
        return null;
      }
    };

    Promise.all([downloadImage(logo)]).then(([downloadedBrandImage]) => {
      setLogoSrc(downloadedBrandImage);
    });
  }, [logo]);

  function detectOS() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Android detection
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
    return "unknown";
  }

  const onClickAddToApple = async () => {
    setLoading(true); // Show the spinner
    await getAddToAppleWallet({
      shopperUUID: loggedInUser?.uuid ?? "",
      programUUID: selectedProgram?.uuid ?? "",
    });
    setLoading(false); // Hide the spinner
    //close the modal
    //setOpen(false);
    console.log("Add to Apple Wallet");
  };

  const onClickAddToGoogle = async () => {
    setLoading(true); // Show the spinner
    const pass = getAddToGoogleWallet({
      shopperUUID: loggedInUser?.uuid ?? "",
      programUUID: selectedProgram?.uuid ?? "",
    });
    setLoading(false); // Hide the spinner
    //setOpen(false);
    console.log("Add to Google Wallet");
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      await updateName({
        shopperUuid: loggedInUser?.uuid ?? "",
        programUuid: selectedProgram?.uuid ?? "",
        emailOptIn: isChecked,
      });
      setOpen(false);
    } catch (error) {
      console.error("Failed to update name", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCheckboxChange: CheckboxProps["onChange"] = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Modal
        open={open}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        centered={true}
        className="modal"
        footer={
          <div className="footer-area">
            <button
              className="accept-button active"
              onClick={handleCancel}
              key={"skip"}
            >
              Skip
            </button>
            {/* <button
              className={`accept-button ${isChecked ? "activee" : ""}`}
              onClick={handleOk}
              key={"Accept"}
              disabled={!isChecked} // Disable button if checkbox is not checked
            >
              Accept
            </button> */}
          </div>
        }
      >
        {/* <p className="brandName">{brandName}</p> */}
        <p className="slogan">
          Thank you for creating an account with meed, and receiving your first
          voucher with {brandName}
        </p>
        <img
          className="brandImage"
          src={logo ? logoSrc || "" : BrandLogoPlaceholder}
          alt="placeholder"
        />

        {os === "iOS" && (
          <img
            src={AddtoAppleIcon}
            className={"add-to-applepopup"}
            onClick={onClickAddToApple}
          />
        )}

        {os === "Android" && (
          <img
            src={AddtoGoogleIcon}
            className={"add-to-applepopup"}
            onClick={onClickAddToGoogle}
          />
        )}

        {/* <Checkbox
          className="model-checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        >
          By ticking this box, I agree to share my email with {brandName}
        </Checkbox>

        <div className="auth__TnC">
          <a
            href="https://www.meedloyalty.com/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            className="terms-button"
          >
            View Organisation Privacy Policy
          </a>
        </div> */}
      </Modal>
    </>
  );
};

export default PopUpModal;
