import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../services/api/axiosIntercept";

const useGetVerified = () => {
  const navigate = useNavigate();
  //const getLogout = useGetLogout();

  const getVerified = async () => {
    try {
      axiosInstance
        .get(`${process.env.REACT_APP_API_URL}v3/auth/shopper/pwa/verify`, {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        })
        .then((res) => {
          if (!res.data.authenticated) {
            notification.info({
              message: "Please Login again",
              description: `Your session expired`,
            });
            navigate("/");
          } else {
            console.log("verified");
          }
        });
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  };

  return getVerified;
};

export default useGetVerified;
