import backendImageApi from "./backendImageApi";
import { GetImage } from "./getImage";

export interface ProgramInfo {
  logoUrl: Promise<string>;
  programData: any; // Replace `any` with the actual type of program data if known
}

export const GetProgramLogo = async (uuid: string): Promise<ProgramInfo> => {
  console.log("GetImage", uuid);
  const response = await backendImageApi.get(
    `v2/membership/program/${uuid}`,
    {},
  );
  console.log("objectUrl", response.data["logoImageSha"]);
  console.log("programInfo", response.data);

  const newlogoUrl = GetImage(response.data["logoImageSha"]);
  let programInfo = null;

  programInfo = GetProgramInfo(response.data);

  return {
    logoUrl: newlogoUrl,
    programData: programInfo,
  };
};

export const GetProgramInfo = (programData: any) => {
  // Extract and return the necessary program information
  // Replace `any` with the actual type of program data if known

  const programDetails = programData["programDetails"];
  const welcomeVoucher = programData["welcomeVoucher"] || {};

  return {
    // Extract specific fields from programDetails
    backgroundImageId: programDetails["imageURL"],
    programName: programDetails["name"],
    brandColor: programDetails["brandColor"],
    brandPattern: programDetails["brandPattern"],

    // Extract specific fields from welcomeVoucher, using optional chaining
    imageId: welcomeVoucher["imageId"] || null,
    itemName: welcomeVoucher["campaignName"] || null,
    valueDescription: welcomeVoucher["valueDescription"] || null,
    // Add other fields as needed
  };
};
