import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useLoggedInUser from "../hooks/useLoggedInUser";

const RequireAuthenticationLayout: React.FC = () => {
  const loggedInUser = useLoggedInUser();
  const { pathname } = useLocation();

  if (!loggedInUser) {
    return (
      <Navigate
        to={{
          // Redirect to / (login page)
          pathname: "/",
          search: new URLSearchParams({
            redirect: pathname,
          }).toString(),
        }}
      />
    );
  }

  return <Outlet />;
};

export default RequireAuthenticationLayout;
