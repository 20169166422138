import React, { useEffect } from "react";
import "../../shared/imports/_variables.css";
import "./about.css";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import Card from "../../Components/card/card";
import Wysiwyg from "../../Components/wysiwyg/wysiwyg";
import getSelectedProgram from "../../hooks/useSelectedProgram";

import { animate } from "motion";

function About() {
  const selectedProgram = getSelectedProgram();
  useEffect(() => {
    animate(
      ".about__section",
      {
        // Keyframes (array or single value).
        opacity: [0, 1],
      },
      {
        duration: 0.8,
      },
    );
  });
  // console.log(selectedProgram);
  return (
    <div className="pwa-page">
      <PWAHeader />
      <Container className="about">
        <h1 className="about__heading">About</h1>
        <div className="about__body">
          <Card className="about__section">
            <Wysiwyg className="about__description">
              <p style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                {selectedProgram?.description}
              </p>
            </Wysiwyg>
          </Card>
          {/* <Card className="about__section">
            <div className="about__social-media">
              <Media url="http://instagram.com" icon={MediaWebSymbol} />
              <Media url="http://instagram.com" icon={MediaInstagramSymbol} />
              <Media url="http://instagram.com" icon={MediaFacebookSymbol} />
            </div>
          </Card> */}
        </div>
      </Container>
      <PWANav />
    </div>
  );
}

export default About;
