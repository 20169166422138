import React, { useEffect, useState } from "react";
import "./pwa-nav.css";
import { NavLink } from "react-router-dom";
import { ReactComponent as HomeSymbol } from "../../assets/symbols/home.svg";
import { ReactComponent as LocationSymbol } from "../../assets/symbols/location.svg";
import { ReactComponent as AboutSymbol } from "../../assets/symbols/about.svg";
import MeedNavIcon from "../../assets/icons/meed-nav@3x.png";
import { AppRoute } from "../../shared/const";
import { animate, stagger } from "motion";
import { Button } from "antd";
import { CircleMenuComponent } from "../../Components/CircleMenu/testMenu";

function PWANav() {
  const [isHomeLinkDisabled, setHomeLinkDisabled] = useState(false);
  const [isMeedPage, setIsMeedPage] = useState(false);

  const navHideAnimation = () => {
    animate(
      "#HomeNavButton",
      { transform: "translateX(100%)", opacity: 0 },
      {
        delay: stagger(0.1),
        duration: 0.8,
        easing: "ease",
      },
    ).finished.then(() => {
      setHomeLinkDisabled(true);
    });
  };

  const showHideAnimation = () => {
    setHomeLinkDisabled(false);
    animate(
      "#HomeNavButton",
      { transform: "translateX(0%)", opacity: 1 },
      {
        delay: stagger(0.1),
        duration: 0.8,
        easing: "ease",
      },
    );
  };

  useEffect(() => {
    if (
      AppRoute.Meed === window.location.pathname ||
      AppRoute.Profile === window.location.pathname ||
      AppRoute.Discovery === window.location.pathname
    ) {
      setIsMeedPage(true);
      setHomeLinkDisabled(true);
    }
  }, []);

  const onClickToggle = () => {
    if (!isHomeLinkDisabled) {
      navHideAnimation();
    } else {
      showHideAnimation();
    }
  };

  return (
    <nav className="pwa-nav">
      <NavLink
        to={AppRoute.Home}
        className={({ isActive }) =>
          "pwa-nav__link" +
          (isActive ? " pwa-nav__link--current" : "") +
          (isHomeLinkDisabled ? " disabled" : "") +
          (isMeedPage ? " opacity-zero" : "")
        }
        id="HomeNavButton"
      >
        <HomeSymbol className="pwa-nav__symbol" />
        <div className="pwa-nav__label">Home</div>
      </NavLink>

      {/* <NavLink
        to={AppRoute.Location}
        className={({ isActive }) =>
          "pwa-nav__link" +
          (isActive ? " pwa-nav__link--current" : "") +
          (isHomeLinkDisabled ? " disabled" : "")
        }
        id="HomeNavButton"
      >
        <LocationSymbol className="pwa-nav__symbol" />
        <div className="pwa-nav__label">Location</div>
      </NavLink> */}
      <NavLink
        to={AppRoute.About}
        className={({ isActive }) =>
          "pwa-nav__link" +
          (isActive ? " pwa-nav__link--current" : "") +
          (isHomeLinkDisabled ? " disabled" : "") +
          (isMeedPage ? " opacity-zero" : "")
        }
        id="HomeNavButton"
      >
        <AboutSymbol className="pwa-nav__symbol" />
        <div className="pwa-nav__label">About</div>
      </NavLink>
      {/* <TestMenuComponent /> */}
      {/* <NavLink
        to={AppRoute.Meed}
        className={({ isActive }) =>
          "pwa-nav__link" + (isActive ? " pwa-nav__link--current" : "")
        }
      >
        <img className="pwa-nav__icon" src={MeedNavIcon} alt="" />
        <div className="pwa-nav__label">meed</div>
      </NavLink> */}
      <div className="pwa-nav__link meedbutton" onClick={onClickToggle}>
        <CircleMenuComponent />
      </div>
    </nav>
  );
}

export default PWANav;
